<template>
  <b-overlay
    :show="$apollo.loading"
    spinner-variant="primary"
  >
    <section class="grid-view">
      <b-card
        v-for="(Review, i) in Reviews"
        :key="i"
        :title="Review.title"
        class="ecommerce-card"
        no-body
      >
        <b-card-body>
          <b-card-title>{{ Review.title }}</b-card-title>
          <b-card-sub-title>Type:
            <b-badge variant="light-primary">
              {{ Review.type }}
            </b-badge>
          </b-card-sub-title>
        </b-card-body>

        <!-- Product Actions -->
        <div class="item-options text-center">
          <b-link
            v-if="Review.review_status==='disabled'"
            :to="`/mentor-portal/programs/review/evaluations/${$route.params.id}/${Review.id}/${$route.params.sid}`"
            class="btn btn-success btn-cart"
          >
            <span>Report</span>
          </b-link>
          <b-link
            :to="`/mentor-portal/programs/review/score/${$route.params.id}/${Review.id}/${$route.params.sid}/${$route.params.pid}`"
            class="btn btn-primary btn-cart"
          >
            <span>View / Submit</span>
          </b-link>
        </div>
      </b-card>
    </section>
    <b-modal
      id="selectStartup"
      ok-only
      ok-title="OK"
      size="lg"
      title="Report"
    >
      <p class="my-1">
        No report available
      </p>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BBadge, BCard, BCardBody, BCardSubTitle, BCardTitle, BLink, BOverlay,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
// import store from '@/store'

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BBadge,
    BOverlay,
    BLink,
  },
  data() {
    return {
      Reviews: [],
    }
  },
  apollo: {
    Reviews: {
      query() {
        return gql`
      {
      programs_assignmenttable(order_by: {id: desc}, where: {review_status: {_is_null: false, _neq: ""}, progress_stage_id: {_eq: ${this.$route.params.mid} }}) {
          id
          title
          status
          review_status
          type
        }
      }`
      },
      update: data => data.programs_assignmenttable,
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
